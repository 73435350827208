<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'SubitemCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="search"
                    label="Search"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="subitems"
                item-key="id"
                :search="search"
                hide-default-footer
                :page.sync="page"
                :items-per-page="itemsPerPage"
                class="elevation-1"
                @page-count="pageCount = $event"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>{{ row.item.product && row.item.product.name }}</td>
                    <td>
                      {{ row.item.qty || 0 }}
                      {{ row.item.unit && row.item.unit.name }}
                    </td>
                    <td>{{ row.item.item && row.item.item.name }}</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'SubitemEdit',
                          params: { id: row.item.id },
                        }"
                        class="badge badge-warning mr-1"
                        >Edit</router-link
                      >
                      <button
                        @click="deleteSubitem(row.item.id)"
                        class="badge badge-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <nav class="d-inline-block">
                <ul class="pagination mb-0">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import swal from "sweetalert";
import { SubitemRepository } from "../../repositories/RepositoryFactory";

export default {
  name: "SubItem",
  data() {
    return {
      name: "Sub Item",
      subitems: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      return SubitemRepository.get().then(({ data }) => {
        this.subitems = data.data;
      });
    },
    deleteSubitem(id) {
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          SubitemRepository.delete(id)
            .then(() => {
              this.getData();
              swal("Poof! Your subitem file has been deleted!", {
                icon: "success",
              });
            })
            .catch((err) => {
              swal("Error!", err.message, "error");
            });
        } else {
          swal("Your subitem file is safe!");
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Product",
          value: "product.name",
        },
        {
          text: "Unit",
          value: "unit.name",
        },
        {
          text: "Item",
          value: "item.name",
        },
        {
          text: "Action",
          value: "action",
        },
      ];
    },
  },
};
</script>
